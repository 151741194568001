<template>
  <div class="headPage" :style="{backgroundImage: `url(${img.customized.ver3.topBgAi})`}">
    <div class="city">
      <span class="market">深圳市</span>
      <span class="weather-degree ml20"><img :src="weather.url" alt="" class="weather"><i class="ml15">{{weather.type}}</i><i class="ml15">{{weather.degree}}℃</i></span>
      <span class="weather-degree ml20">风向：{{weather.winddirection}} ({{weather.windpower}})级</span>
    </div>
    <div class="logo">
      <!-- <img :src="img.customized.ver3.logo" alt="">
      <span>永恒光智慧照明管理平台</span> -->
    </div>
    <div class="sys">
      <div class="time">{{time}}</div>
      <div class="date-box">
        <div class="week">{{weeks[week]}}</div>
        <div class="date">{{date}}</div>
      </div>
      <div class="sys-but">
        <img :src="isFullScreen?img.customized.ver1.screenTrue:img.customized.ver1.screenFalse" alt="" class="ml10" @click="fullScreen()">
        <img :src="img.customized.ver1.console" alt="" class="consoleIcon" @click="routerPage()">
      </div>
    </div>
  </div>
</template>
<script>
import { weatherVer1Data } from '@/plugins/weather'
export default {
  name: 'headPage',
  components: {
  },
  props: {
  },
  data() {
    return {
      date: '',
      time: '',
      week: 0,
      isFullScreen: true,
      weeks: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      weather: {
        icon: '--',
        degree: '--',
        type: '--',
        winddirection: '--',
        windpower: '--',
        url: `//${this.domains.imgs}/imgs/customized/weather1/yin.png`,
      },
    }
  },
  mounted: function () {
    this.setDateTime()
    this.QueryWeather()
  },
  methods: {
    routerPage() {
      this.$router.push('/index/light/road/monitor');
    },
    fullScreen() {
      this.isFullScreen = !this.isFullScreen;
      if (!this.isFullScreen) {
        if (!document.fullscreenElement) {
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) { // Firefox
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
            document.documentElement.webkitRequestFullscreen();
          } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
            document.documentElement.msRequestFullscreen();
          }
        } else {
          // 如果当前在全屏模式
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
          }
        }
      }
    },
    setDateTime: function () {
      let now = new Date();
      this.date = now.format('yyyy-MM-dd');
      this.time = now.format('HH:mm:ss');
      this.week = now.getDay();
      setTimeout(this.setDateTime, 1000);
    },
    QueryWeather() {
      this.$axios.post(`visual/home/QueryWeather`, { city: '深圳市' }).then(res => {
        if (res.code == 0) {
          let city = res.data
          this.weather.degree = res.data.temperature //天气
          this.weather.type = city.weather
          this.weather.winddirection = city.winddirection
          this.weather.windpower = city.windpower
          weatherVer1Data.forEach(el => {
            el.have.forEach(ele => {
              if (ele == city.weather) {
                this.weather.url = el.url
              }
            })
          })
        } else {
          this.weather.degree = '----'
          this.weather.type = '----'
          this.weather.winddirection = '----'
          this.weather.windpower = '----'
          this.weather.url = weatherVer1Data[0].url //默认
        }
      })
    },
  }
}
</script>
<style scoped>
.headPage {
  width: 100%;
  height: 64px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 50;
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 47px;
  height: 50px;
  margin-right: 10px;
}
.logo span {
  font-family: aliheiti;
  font-weight: bold;
  font-size: 34px;
  color: #ffffff;
  font-style: italic;
  background: linear-gradient(180deg, #feffff 13.6474609375%, #e2ecfb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 15px;
}
.city {
  width: 390px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding-left: 40px;
}
.sys {
  width: 390px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right:40px;
}
.market {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.weather-degree {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
}
.time {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 34px;
  color: #fcfcfd;
  margin-right: 11px;
}
.date-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.week,
.date {
  font-family: Source Han Sans SC;
  font-size: 14px;
  color: #fcfcfd;
  line-height: 18px;
}
.sys-but {
  align-content: center;
  cursor: pointer;
}
.sys-but img {
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: inline-block;
}
.weather {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-top: -5px;
}
.consoleIcon {
  margin-left: 20px;
}
</style>
