<template>
  <div class="totalPage">
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon1" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total1">
        <div class="stat-box-name">项目总数(个)</div>
        <div class="stat-box-num">
          {{statData.projectCount}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon2" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total2">
        <div class="stat-box-name">配电箱总数(个)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.powerboxCount)" :start-val="0" :key="countIndex"></count-up>{{statData.powerboxCount>100000?'万':''}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon3" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total3">
        <div class="stat-box-name">单灯总数(个)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.lightCount)" :start-val="0" :key="countIndex"></count-up>{{statData.lightCount>100000?'万':''}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon4" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total4">
        <div class="stat-box-name">能耗总数(KW·h)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.energyTotal)" :start-val="0" :key="countIndex"></count-up>{{statData.energyTotal>100000?'万':''}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon5" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total5">
        <div class="stat-box-name">节能总数(KW·h)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.energyConservation)" :start-val="0" :key="countIndex"></count-up>{{Math.abs(statData.energyConservation)>100000?'万':''}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon6" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total6">
        <div class="stat-box-name">减碳总数(千克)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.carbonReduction)" :start-val="0" :key="countIndex"></count-up>{{Math.abs(statData.carbonReduction)>100000?'万':''}}
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver3.totalIcon7" alt="" class="stat-box-img">
      <div class="stat-box-total stat-box-total7">
        <div class="stat-box-name">减少砍伐树木(棵)</div>
        <div class="stat-box-num">
          <count-up :end-val="conversion(statData.treeReduction)" :start-val="0" :key="countIndex"></count-up>{{Math.abs(statData.treeReduction)>100000?'万':''}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountUp from 'vue-countup-v2';
export default {
  name: 'totalPage',
  components: {
    CountUp
  },
  props: {
  },
  data() {
    return {
      statData: {
        carbonReduction: 194556440.59,
        energyConservation: 349418894.74,
        energyTotal: 34419595.26,
        lightCount: 29228,
        powerboxCount: 1613,
        projectCount: 410,
        treeReduction: 17186965,
      },
      countIndex: 0,
      stopTime: null,
    }
  },
  mounted: function () {
    this.getStat()
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 8000);
  },
  methods: {
    getStat() {
      this.$axios.post(`visual/home/QueryDeviceAndEnergy`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'statData', res.data);
        }
      })
    },
    conversion(num) {
      num = Math.abs(num);
      if (num < 100000) {
        return num * 1;
      }
      let digit = ''
      digit = (num / 10000).toFixed(2)
      return digit * 1;
    }
  },
  beforeDestroy() {
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.totalPage {
  display: flex;
  flex-direction: column;
}
.stat-box-img {
  width: 42px;
  height: 50px;
  position: absolute;
  z-index: 20;
}
.stat-box {
  display: flex;
  margin-bottom: 30px;
  height: 50px;
  position: relative;
}
.stat-box-total {
  position: absolute;
  z-index: 10;
  left: 21px;
  align-content: center;
  width: 130px;
  height: 50px;
  background: linear-gradient(
    to right,
    rgba(29, 75, 124, 1),
    rgba(29, 75, 124, 0.5),
    rgba(0, 0, 0, 0)
  );
  padding-left: 28px;
}
.stat-box-total1 {
  background: linear-gradient(
    to right,
    rgba(37, 94, 205, 1),
    rgba(37, 94, 205, 0.9),
    rgba(37, 94, 205, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-total2 {
  background: linear-gradient(
    to right,
    rgba(10, 114, 202, 1),
    rgba(10, 114, 202, 0.9),
    rgba(10, 114, 202, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-total3 {
  background: linear-gradient(
    to right,
    rgba(5, 153, 178, 1),
    rgba(5, 153, 178, 0.9),
    rgba(5, 153, 178, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-total4 {
  background: linear-gradient(
    to right,
    rgba(58, 162, 129, 1),
    rgba(58, 162, 129, 0.9),
    rgba(58, 162, 129, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-total5 {
  background: linear-gradient(
    to right,
    rgba(140, 158, 99, 1),
    rgba(140, 158, 99, 0.9),
    rgba(140, 158, 99, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-total6 {
  background: linear-gradient(
    to right,
    rgba(167, 150, 95, 1),
    rgba(167, 150, 95, 0.9),
    rgba(167, 150, 95, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-total7 {
  background: linear-gradient(
    to right,
    rgba(74, 170, 209, 1),
    rgba(74, 170, 209, 0.9),
    rgba(74, 170, 209, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.stat-box-name {
  margin-top: 1px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 12px;
  color: #EDF7FF;
}
.stat-box-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 20px;
  color: #F6FAFD;
  /* text-shadow: 0px 0px 18px #023657;
  background: -webkit-linear-gradient(
    -90deg,
    #fef9f2 16.6748046875%,
    #f6bc69 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
</style>
