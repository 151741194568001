<template>
  <div class="allTotalPage">
    <titlePage :option='title'></titlePage>
    <div class="allTotal-box">
      <div class="all-date">
        <div :class="['all-date-but',allDateIndex==0?'all-date-but2':'',]" @click="allDateBut(0)">年</div>
        <div :class="['all-date-but',allDateIndex==1?'all-date-but2':'',]" @click="allDateBut(1)">月</div>
        <div :class="['all-date-but',allDateIndex==2?'all-date-but2':'',]" @click="allDateBut(2)">日</div>
      </div>
      <div class="total-box">
        <div class="total-list total-list1">
          <img :src="img.customized.ver3.energyNum1" alt="" class="total-list-img">
          <div class="total-list-con">
            <div class="total-list-unit">总能耗(KWh)</div>
            <div class="total-list-num textSiz"><count-up :end-val="conversion(showData.energyTotal)" :start-val="0" :key="countIndex"></count-up>{{showData.energyTotal>100000?'万':''}}</div>
          </div>
        </div>
        <div class="total-list total-list2">
          <img :src="img.customized.ver3.energyNum3" alt="" class="total-list-img">
          <div class="total-list-con">
            <div class="total-list-unit">总节约能源(KWh)</div>
            <div class="total-list-num textSiz"><count-up :end-val="conversion(showData.energyConservation)" :start-val="0" :key="countIndex"></count-up>{{showData.energyConservation>100000?'万':''}}
            </div>
          </div>
        </div>

      </div>
      <div class="total-box mt15">
        <div class="total-list total-list3">
          <img :src="img.customized.ver3.energyNum2" alt="" class="total-list-img">
          <div class="total-list-con">
            <div class="total-list-unit">总减少碳排放(kg)</div>
            <div class="total-list-num textSiz" style="color:#54F2DA">
              <count-up :end-val="conversion(showData.carbonReduction)" :start-val="0" :key="countIndex"></count-up>{{showData.carbonReduction>100000?'万':''}}
            </div>
          </div>
        </div>
        <div class="total-list total-list4">
          <img :src="img.customized.ver3.energyNum4" alt="" class="total-list-img">
          <div class="total-list-con">
            <div class="total-list-unit">总节省电(度)</div>
            <div class="total-list-num textSiz" style="color:#F1C179">
              <count-up :end-val="conversion(showData.treeReduction)" :start-val="0" :key="countIndex"></count-up>{{showData.treeReduction>100000?'万':''}}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="total-line">
        <div class="total-line-title">
          <div class="line-title-list">
            <i style="background:#4F9DFC"></i>
            <span>每日能耗</span>
          </div>
          <div class="line-title-list">
            <i style="background:#3FEED3"></i>
            <span>节约能源</span>
          </div>
          <div class="line-title-list">
            <i style="background:#C775DE"></i>
            <span>改造前功率</span>
          </div>
        </div>
        <div class='total-line-box'>
          <chartsLine :option="lightLine" id="allTotalPage" :key="timer"></chartsLine>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import titlePage from '../pubilc/title'
import CountUp from 'vue-countup-v2';
import { mapState } from 'vuex'
export default {
  name: 'allTotalPage',
  components: {
    titlePage,
    CountUp
  },
  props: {

  },
  data() {
    return {
      timer: 0,
      allDateIndex: 0,
      title: { zh: '总能耗统计', en: 'TOTAL ENERGY CONSUMPTION' },
      showData: {
        carbonReduction: 0,
        energyConservation: 0,
        energyTotal: 0,
        lightCount: 0,
        powerboxCount: 0,
        projectCount: 0,
        treeReduction: 0,
      },
      data: [],
      dataYear: [],
      dataMonth: [],
      dataDay: [],
      dayEnergy: [],
      lightLine: {
        color: ['#4F9DFC', '#3FEED3', '#C775DE'],
        title: '',
        isAnimation: false,
        x: [],
        unit: '',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        series: [
          {
            name: '每日能耗(KWh)',
            data: []
          },
          {
            name: '节约能耗(KWh)',
            data: []
          },
          {
            name: '改造前功率(KW)',
            data: []
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          // areaStyle: {} //下阴影
        }
      },
      timeout: null,
      countIndex: 0,
      stopTime: null,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize() {
      this.timer++;
    },
  },
  mounted: function () {
    this.QueryTotalEnergy()
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 14000);
  },
  methods: {
    QueryTotalEnergy() {
      this.$axios.post(`visual/home/QueryTotalEnergy`, { type: 'year' }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'dataYear', res.data);
          this.$set(this, 'showData', res.data);
        }
        this.settimeOn()
      })
      this.$axios.post(`visual/home/QueryTotalEnergy`, { type: 'month' }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'dataMonth', res.data);
        }
      })
      this.$axios.post(`visual/home/QueryTotalEnergy`, { type: 'day' }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'dataDay', res.data);
        }
      })
      this.$axios.post(`visual/home/QueryDayEnergy`, { type: 'day' }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'dayEnergy', res.data);
          this.lightLine.x = []
          this.lightLine.series[0].data = []
          this.lightLine.series[1].data = []
          this.lightLine.series[2].data = []
          res.data.forEach(el => {
            this.lightLine.x.push(el.date)
            this.lightLine.series[0].data.push(el.lightBeforeEnergy)
            this.lightLine.series[1].data.push(el.energyConservation)
            this.lightLine.series[2].data.push(el.lightBeforePower)
          });
          this.timer++;
        }
      })
    },
    allDateBut(num) {
      this.allDateIndex = num;
    },
    settimeOn() {
      this.timeout = setTimeout(() => {
        this.allDateIndex++;
        if (this.allDateIndex == 3) {
          this.allDateIndex = 0;
        }
        switch (this.allDateIndex) {
          case 0:
            this.$set(this, 'showData', this.dataYear);
            break;
          case 1:
            this.$set(this, 'showData', this.dataMonth);
            break;
          case 2:
            this.$set(this, 'showData', this.dataDay);
            break;
        }
        this.settimeOn();
      }, 7000);
    },
    conversion(num) {
      if (num < 100000) {
        return num * 1;
      }
      let digit = ''
      digit = (num / 10000).toFixed(2)
      return digit * 1;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.allTotalPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.allTotal-box {
  width: 100%;
  padding-left: 22px;
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}
.all-date {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.total-box {
  display: flex;
  justify-content: space-between;
}
.all-date-but {
  width: 38px;
  height: 22px;
  /* opacity: 0.8; */
  background: linear-gradient(
    0deg,
    rgba(68, 150, 213, 0.8),
    rgba(18, 48, 107, 0.8)
  );
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #7ca3c7;
  text-align: center;
  line-height: 22px;
  margin-left: 10px;
  cursor: pointer;
}
.all-date-but2 {
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(18, 48, 107, 0.8), rgba(129, 221, 255, 0.8)) 1 1;
  color: #ffffff;
}
.total-list {
  width: calc((100% - 13px) / 2);
  height: 72px;
  box-shadow: inset 0px 0px 40px 0px #0a6db3;
  border: 1px solid;
  /* border-image: linear-gradient(180deg, #39c0ff, #085996) 1 1; */
  text-align: center;
  align-content: center;
  display: flex;
  padding-left: 30px;
  align-items: center;
  position: relative;
}
.total-list1{
  box-shadow: inset 0px 0px 35px 0px #04B5FF;
}
.total-list2{
   box-shadow: inset 0px 0px 35px 0px #04CFED;
}
.total-list3{
   box-shadow: inset 0px 0px 35px 0px #637DFF;
}
.total-list4{
   box-shadow: inset 0px 0px 35px 0px #F5E575;
}
.total-list1::after {
  content: "";
  position: absolute; /* 绝对定位 */
  bottom: -1px;
  right: -6px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #39c0ff;
  border-right: 8px solid transparent;
  transform: rotate(132deg);
  z-index: 40;
}
.total-list1::before {
  content: "";
  position: absolute; /* 绝对定位 */
  bottom: -3px;
  right: -10px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #153677;
  border-right: 13px solid transparent;
  transform: rotate(132deg);
  z-index: 30;
}
/* .total-list1::before {
  content: "";
  position: absolute; 
  bottom: -3px;
  left: -10px;
  right: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #153677;
  border-right: 13px solid transparent;
  transform: rotate(132deg);
  z-index: 30;
} */
.total-list2::after {
  content: "";
  position: absolute; /* 绝对定位 */
  bottom: -1px;
  left: -6px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #39c0ff;
  border-right: 8px solid transparent;
  transform: rotate(224deg);
  z-index: 40;
}
.total-list2::before {
  content: "";
  position: absolute; /* 绝对定位 */
  bottom: -3px;
  left: -10px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #153677;
  border-right: 13px solid transparent;
  transform: rotate(224deg);
  z-index: 30;
}
.total-list3::after {
  content: "";
  position: absolute; /* 绝对定位 */
  top: -1px;
  right: -6px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #39c0ff;
  border-right: 8px solid transparent;
  transform: rotate(46deg);
  z-index: 40;
}
.total-list3::before {
  content: "";
  position: absolute; /* 绝对定位 */
  top: -3px;
  right: -10px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #153677;
  border-right: 13px solid transparent;
  transform: rotate(46deg);
  z-index: 30;
}
.total-list4::after {
  content: "";
  position: absolute; /* 绝对定位 */
  top: -1px;
  left: -6px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #39c0ff;
  border-right: 8px solid transparent;
  transform: rotate(310deg);
  z-index: 40;
}
.total-list4::before {
  content: "";
  position: absolute; /* 绝对定位 */
  top: -2px;
  left: -11px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-bottom: 13px solid #153677;
  border-right: 13px solid transparent;
  transform: rotate(310deg);
  z-index: 30;
}
.total-list-img {
  width: 48px;
  height: 48px;
}
.horn {
  width: 6px;
  height: 6px;
  border: 3px solid #6dafe2;
  position: absolute;
}
.horn1 {
  border-bottom: none;
  border-right: none;
  top: 0;
  left: 0;
}
.horn2 {
  border-bottom: none;
  border-left: none;
  top: 0;
  right: 0;
}
.horn3 {
  border-top: none;
  border-right: none;
  bottom: 0;
  left: 0;
}
.horn4 {
  border-top: none;
  border-left: none;
  bottom: 0;
  right: 0;
}
.total-list-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  line-height: 24px;
  margin-top: 5px;
}
.total-list-unit {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #B0CFED;
}
.total-line-box {
  width: 100%;
  height: calc(100% - 40px);
  margin-bottom: 20px;
}
.total-line {
  flex: 1 1 200px;
}
.total-line-title {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
}
.line-title-list i {
  width: 8px;
  height: 8px;
  background: #4f9dfc;
  display: inline-block;
  margin: 0 5px 0 15px;
}
.line-title-list span {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #7c92af;
}
.total-list-con {
  text-align: left;
  margin-left: 10px;
}
.textSiz {
  display: inline-block;
  animation: jump 2s 3;
}

@keyframes jump {
  20% {
    -webkit-transform: rotate(5deg);
  }

  40% {
    -webkit-transform: rotate(-5deg);
  }

  60% {
    -webkit-transform: rotate(2deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}
</style>
