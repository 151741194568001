<template>
  <!-- fullBg -->
  <div class="all-page" id='allPageVer3' >
    <div class="made-page" :style="{backgroundImage: `url(${img.customized.ver3.fullBg})`}"  ref="fullscreenElement">
      <div class="made-head">
        <headPage />
      </div>
      <div class="made-box">
        <div class="made-left">
          <div class="settingPage">
            <settingPage></settingPage>
          </div>
          <div class="countPage">
            <countPage></countPage>
          </div>
        </div>
        <div class="made-gis">
          <gisPage />
          <div class='gisMask1' :style="{backgroundImage: `url(${img.customized.ver3.maskGis2})`}">
          </div>
          <div class='gisMask2' :style="{backgroundImage: `url(${img.customized.ver3.maskGis4})`}">
          </div>
          <div class='gisMask3' :style="{backgroundImage: `url(${img.customized.ver3.maskGis1})`}">
          </div>
          <div class='gisMask4' :style="{backgroundImage: `url(${img.customized.ver3.maskGis3})`}">
          </div>
        </div>
        <div class="made-right">
          <div class="made-right-box1">
            <allTotalPage></allTotalPage>
          </div>
          <div class="made-right-box2">
            <contrastPage></contrastPage>
          </div>
          <div class="made-right-box">
            <sortBox1Page></sortBox1Page>
          </div>
        </div>
      </div>
      <!-- <div class="made-box">
        <div class="made-left">
          <settingPage class="left-box" /> 
          <device class="left-box" /> 
        </div>
        <div class="made-gis">
          <gisPage />
        </div>
        <div class="made-right">
          <energyPage />
        </div>             
      </div> -->
    </div>
  </div>
</template>
<script>

import headPage from './head/Index'
// import autofit from "autofit.js"
import settingPage from './left/setting'
import countPage from './left/count'
import allTotalPage from './right/allTotal'
import contrastPage from './right/contrast'
import sortBox1Page from './right/sortBox1'
// import device from './left/device'
import gisPage from './gis/index'

import autofit from "autofit.js"
import { mapState } from 'vuex'
export default {
  name: 'customizedHome',
  components: {
    headPage,
    settingPage,
    countPage,
    // device,
    allTotalPage,
    sortBox1Page,
    contrastPage,
    gisPage,
  },
  props: {
  },
  data() {
    return {
      projectId: 0,
      menus: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize() {
      // this.adjustFontSize()
    }
  },
  mounted: function () {
    // this.enterFullscreen();
    autofit.init({
      designHeight: 1080,
      designWidth: 1920,
      renderDom: "#allPageVer3",
      resize: true,
      ignore: [{ el: '.autofitHide' }]
    }, false)
    // autofit.init({
    //   dw: 1920,
    //   dh: 1080,
    //   // dw: 3072,
    //   // dh: 1536,
    //   el: "#allPageVer3",
    //   resize: true,
    //   ignore: [{ el: '.autofitHide' }]
    // }, false)
    // setTimeout(() => {
    //   this.enterFullscreen();
    // }, 200);
  },
  methods: {
    adjustFontSize() {
      const remSize = (document.documentElement.clientWidth / 30.72).toFixed(4)//1920 ---- 19.2 
      document.documentElement.style.fontSize = remSize / 100 * 16 + 'px';
      console.log(remSize)

      // const viewportWidth = window.innerWidth;
      // const baseFontSize = viewportWidth > 1200 ? 16 : (viewportWidth / 1200) * 16;
      // document.documentElement.style.fontSize = baseFontSize + 'px';
      // console.log(baseFontSize)
    },
    enterFullscreen() {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    },
  },
  beforeDestroy() {
    autofit.off()
  }
}
</script>
<style scoped>
.all-page {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 51, 116, 1);
}
.made-page {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.made-head {
  width: 100%;
  height: 64px;
}
.made-box {
  width: calc(100%);
  height: calc(100% - 48px);
  padding: 15px 24px;
  display: flex;
  justify-content: space-between;
}
.made-left,
.made-right {
  display: flex;
  width: calc(100% / 7 * 1.8);
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 40;
  position: relative;
}
.settingPage {
  width: 100%;
  height: 50%;
  z-index: 20;
}
.countPage {
  width: 100%;
  height: 50%;
  z-index: 20;
}
.made-gis {
  width: calc(100% / 7 * 3.4);
  height: 100%;
  position: relative;
}
.gisMask1 {
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  width: 78px;
  z-index: 20;
}
.gisMask2 {
  height: 100%;
  position: absolute;
  right: -8px;
  top: 0;
  width: 78px;
  z-index: 20;
}
.gisMask3 {
  height: 71px;
  position: absolute;
  left: 0;
  top: -5px;
  width: 100%;
  z-index: 20;
}
.gisMask4 {
  height: 73px;
  position: absolute;
  left: 0;
  bottom: 247px;
  width: 100%;
  z-index: 20;
}
.made-right-box1 {
  width: 100%;
  height: 28%;
  z-index: 20;
}
.made-right-box2 {
  width: 100%;
  height: 38.66%;
  z-index: 20;
}
.made-right-box {
  width: 100%;
  height: 33.3%;
  z-index: 20;
}
</style>
