<template>
  <div class="gisPage">
    <div class="gis-map">
      <div class="mapPage">
        <gisPage :projectIdNum='projectId' :projectData='projectData' :key='mapGLIndex+ "gis"'  />
      </div>
      <div class="switch-project">
        <div class="project-name" @click="switchProject">
          <div class="project-border"></div>
          <div class="project-text">
            <div class="project-text-name">{{projectTitle()}}</div>
            <Icon type="md-arrow-dropdown" class="project-text-icon" v-if="!projectShow" />
            <Icon type="md-arrow-dropup" class="project-text-icon" v-if="projectShow" />
          </div>
          <div class="project-border"></div>
        </div>
        <div class="project-list" v-if="projectShow">
          <div class="project-list-box" @click="projectList({id:0})">
            <div class="box-con">
              <div :class="['box-circle',projectId==0?'box-circle3':'']">
                <div class="box-circle2" v-if="projectId==0"></div>
              </div>
              <div :class="['box-con-name',projectId==0?'box-con-name2':'']">全部</div>
            </div>
            <div class="box-border1"></div>
            <div class="box-border2"></div>
          </div>
          <div class="project-list-box" v-for="el,index in projectData" :key="index" @click="projectList(el)">
            <div class="box-con">
              <div :class="['box-circle',projectId==el.id?'box-circle3':'']">
                <div class="box-circle2" v-if="projectId==el.id"></div>
              </div>
              <div :class="['box-con-name',projectId==el.id?'box-con-name2':'']">{{el.name}}</div>
            </div>
            <div class="box-border1"></div>
            <div class="box-border2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="gis-stat">
      <div class='gis-module'>
        <installPage></installPage>
      </div>
      <div class='gis-module'>
        <sortBox2Page></sortBox2Page>
      </div>
    </div>
    <div class="dis-total">
      <totalPage />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import mapPage from './map'
import sortBox2Page from './sortBox2'
import installPage from './install'
import totalPage from './total'
import gisPage from './gisHome2'
export default {
  name: 'gisIndex',
  components: {
    // mapPage,
    sortBox2Page,
    installPage,
    totalPage,
    gisPage,
  },
  props: {
  },
  watch: {
    windowOnResize() {
      this.mapGLIndex = new Date().getTime();
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
  },
  data() {
    return {
      mapGLIndex: 0,
      projectData: [],
      projectShow: false,
      projectId: 0,
    }
  },
  mounted: function () {
    this.getProject();
  },
  methods: {
    switchProject() {
      this.projectShow = !this.projectShow;
    },
    projectList(el) {
      this.projectShow = false;
      if (this.projectId == el.id) return false;
      this.mapGLIndex = new Date().getTime();
      this.projectId = el.id;
    },
    projectTitle() {
      const project = this.projectData.find(el => el.id === this.projectId);
      return project?.name || '全部';
    },
    getProject() {
      this.$axios.post(`visual/home/QueryProject`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'projectData', res.data);
          this.mapGLIndex = new Date().getTime();
        }
      })
    },
  }
}
</script>
<style scoped>
.gisPage {
  width: 100%;
  height: 100%;
  position: relative;
}
.dis-total {
  position: absolute;
  z-index: 20;
  left: 30px;
  top: 30px;
  z-index: 30;
}

.gis-map {
  width: 100%;
  height: 75%;
  position: relative;
}
.mapPage {
  width: 100%;
  height: 100%;
}
.gis-stat {
  width: calc(100%);
  height: 25%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 30;
}
.gis-module {
  width: calc(50% - 10px);
  height: 100%;
}

.switch-project {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 50;
}
.project-name {
  width: 180px;
  height: 40px;
  background: linear-gradient(90deg, #123374, #3385D7, #123374);
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.project-border {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #16346F, #A1E5FF, #12306B);
}
.project-text-icon {
  color: #78ADE6;
  font-size: 38px;
  margin-left: 5px;
}
.project-text {
  width: 100%;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #f6fafd;
  display: flex;
  justify-content: center;
}
.project-list {
  position: absolute;
  width: 298px;
  height: 220px;
  right: -50px;
  top: 51px;
  max-height: 300px;
  overflow-y: auto;
  background: linear-gradient(90deg, rgba(28, 65, 137, 0.8), rgba(51, 133, 215, 0.8), rgba(28, 65, 137, 0.8));
  border-radius: 3px;
  cursor: pointer;
  padding: 0 20px 20px;
}
.project-text-name {
  max-width: 100px;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-list-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40px;
}
.box-con {
  text-align: left;
  display: flex;
  align-items: center;
  height: 36px;
}
.box-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #87a4c1;
  margin-right: 5px;
}
.box-con-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #B0CFED;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-con-name2 {
  color: #fff;
}
.box-circle2 {
  width: 6px;
  height: 6px;
  background: #5ed7ff;
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
.box-circle3 {
  border: 1px solid #5ed7ff;
}
.box-border1 {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #1D438B, #BBDDFF, #1C4188);
}
/* .box-border2 {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #062037, #6191b9, #062037);
} */
</style>
