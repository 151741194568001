<template>
  <div class="pole-main" :key="gisIndex">
    <baidu-map class="map" :ak="ak" :center="center" :map-type="mapMenuItem.type" :double-click-zoom="false" @ready="mapReady" :zoom="center.zoom" @zoomend="mapZoomChanged"  :min-zoom="levelSet.min" :scroll-wheel-zoom="true">
      <template v-if="map">
        <template v-if="projectId==0">
          <!-- <template v-for="(el,index) in cityData">
            <bm-boundary :name="el" :strokeWeight="boundaryStyle.strokeWeight"  :strokeColor="boundaryStyle.strokeColor" :fillColor='boundaryStyle.fillColor' :key="index+'boundary'+setZoomNum"></bm-boundary>
          </template> -->
          <template v-for="(el,index) in projectData">
            <bm-marker :dragging="enableMoveDevice" :key="index" :icon="{ url: getImgType(el), size: getSizeType(el) }" :position="{ lng: el.lng, lat: el.lat }" :name="el.name">
            </bm-marker>
          </template>
        </template>
        <template v-else>
          <bm-point-collection :points="getCurrentPoints()" :shape="shapeType" :color="colorType" :size="mapPointSizeType"></bm-point-collection>
        </template>
      </template>
    </baidu-map>
  </div>
</template>
<script>
import ticyJson from './ticy'
import { mapGetters, mapState } from 'vuex'
import { BaiduMap, BmPointCollection,  BmMarker } from '@/components/vue-baidu-map-v3/components'
export default {
  name: 'homeGis12',
  components: {
    BaiduMap,
    // BmBoundary,
    BmMarker,
    BmPointCollection,
  },
  data() {
    return {
      boundaryStyle:{
        strokeColor:'#4f9cf2',
        strokeWeight:1,
        strokeOpacity:0.1,
        fillColor:'rgba(79, 156, 242, 0.3)'
      },
      map: null,
      BMap: null,
      mapStyle: {
        styleJson: require('@/views/customized/public/customized_ver3.json'),
      },
      lampAiUrl: require('@/assets/img/lampStar.png'),
      ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88',
      center: { lng: 113.828333, lat: 22.770866, zoom: 14 },
      levelSet: {
        min: 3, //小于此等级时
        max: 20 //小于此等级时
      },
      mapMenuItem: {
        type: 'BMAP_NORMAL_MAP',
        offset: {
          width: 10,
          height: 10
        }
      },
      gisData: [],
      colorType: '#09dd80',
      shapeType: 'BMAP_POINT_SHAPE_CIRCLE',
      mapPointSizeType: 'BMAP_POINT_SIZE_SMALL',
      isZoomNum: true,
      gisIndex: 0,
      pointBatches: [], // 存放分批后的点数据
      loadedBatches: 0, // 已加载的批次数量
      timeoutId: null,
      batchSize: 4000,
      mapVisible: false,
      cityData: [],
      projectId: 0,
      setZoomNum: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'organs']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  props: {
    projectIdNum: {
      type: Number,
      default: 0
    },
    projectData: {
      type: Array,
      default() { return []; }
    },
    enableMoveDevice: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    projectIdNum: {
      handler(newVal) {
        this.projectId = newVal;
        this.setZoomNum++;
        this.gisIndex = new Date().getTime();
        this.projectHandle();
        this.getGisItem();
        if (this.projectId == 0) {
          this.setZoom()
        }else{
          this.center.zoom = 14;
        }
      },
      immediate: true // 立即触发一次
    },
    windowOnResize() {
      this.getGisItem();
    }
  },
  created: function () {
  },
  mounted: function () {
    this.projectHandle();
    this.gisIndex = new Date().getTime();
  },
  methods: {
    getImgType() {
      return this.lampAiUrl;
    },
    getSizeType() {
      return { width: 32, height: 30 }
    },
    calculateMapBounds() {
      return this.projectData.reduce((acc, el) => {
        if (el.lat > 0 && el.lng > 0) {
          acc.minLat = Math.min(acc.minLat, el.lat);
          acc.maxLat = Math.max(acc.maxLat, el.lat);
          acc.minLng = Math.min(acc.minLng, el.lng);
          acc.maxLng = Math.max(acc.maxLng, el.lng);
        }
        return acc;
      }, {
        minLat: 90,
        maxLat: -90,
        minLng: 180,
        maxLng: -180
      });
    },
    setZoom() {
      const bounds = this.calculateMapBounds();
      this.center.lng = (bounds.minLng + bounds.maxLng) / 2
      this.center.lat = (bounds.minLat + bounds.maxLat) / 2
      this.center.zoom = 5;
    },
    findProvince(city) {
      let provinceName = city;
      for (const province of ticyJson) {
        if (province.city) {
          for (const cityData of province.city) {
            if (cityData.name === city) {
              provinceName = province.name;
              return provinceName;
            }
          }
        }
      }
      return provinceName;
    },
    projectHandle() {
      if (this.projectData.length == 0) { return false }
      const onlyChineseChars = /^[\u4e00-\u9fa5]+$/;
      this.cityData = [...new Set(this.projectData.map(item => item.city))].filter(city => onlyChineseChars.test(city));
      let cityArr = [];
      this.cityData.forEach(el => {
        cityArr.push(this.findProvince(el))
      });
      this.cityData = [...new Set(cityArr)];
      // console.log(this.cityData)
    },

    getCurrentPoints() {
      let points = [];
      for (let i = 0; i < this.loadedBatches; i++) {
        points = points.concat(this.pointBatches[i]);
      }
      return points;
    },
    loadPointsInBatches() {
      this.pointBatches = [];
      // 批次划分数据
      for (let i = 0; i < Math.ceil(this.gisData.length / this.batchSize); i++) {
        const start = i * this.batchSize;
        const end = Math.min(start + this.batchSize, this.gisData.length);
        this.pointBatches.push(this.gisData.slice(start, end));
      }
      this.loadNextBatch();
    },
    loadNextBatch() {
      if (this.loadedBatches < this.pointBatches.length) {
        this.timeoutId = setTimeout(() => {
          this.loadedBatches++;
          this.loadNextBatch(); 
        }, 200);
      }
    },
    mapReady: function (params) {
      this.$nextTick(() => {
        this.map = params.map
        this.BMap = params.BMap
        this.map.setMapStyleV2({
          styleJson: this.mapStyle.styleJson
        })
      });
    },
    mapZoomChanged(e) {
      if (!this.map) return
      this.center.zoom = e.target.getZoom()
      if (this.isZoomNum) {
        this.getGisItem(true);
      }
    },
    dragend() {
      if (!this.map) return
      this.getGisItem(true)
    },
    getGisItem(is) {//获取全部
      if (!this.map || !this.BMap) return;
      if (is) { this.isZoomNum = false }
      if (!this.projectData?.length || !this.projectId || this.projectId == 0) return
      let project = this.projectData.find(el => el.id === this.projectId);
      if (!project) return;
      this.$axios.post(`visual/home/QueryProjectGisItems`, { id: project.id, isVirtual: project.isVirtual }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'gisData', res.data.items);
          this.loadPointsInBatches();
          if (this.center && this.center.lat && this.center.lng) {
            this.center.lat = res.data.lat;
            this.center.lng = res.data.lng;
          }
        }
      })

    },
  },
  beforeDestroy() {
    if (this.map && this.BMap) {
      try {
        const container = this.map.getContainer();
        if (container) container.innerHTML = '';
      } catch (e) {
        console.warn('Cleanup error:', e);
      }
      this.map = null;
      this.BMap = null;
    }
  }
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}
.map {
  width: 100%;
  height: 100%;
  margin: auto;
}
.map >>> .anchorBL {
  display: none;
}
</style>