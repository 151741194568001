<template>
  <div class="countPage2">
    <titlePage :option='title'></titlePage>
    <div class="count-box">
      <div class="dev-box">
        <div class="dev-list">
          <div class="dev-title">
            <div class="dev-name">配电箱总数</div>
            <div class="dev-num"><span class="textSiz"><count-up :end-val="boxList.count*1" :start-val="0" :key="countIndex"></count-up></span><i>个</i></div>
          </div>
          <div class="dev-data">
            <img :src="img.customized.ver3.devBox" alt="" class="ml10">
            <div class="dev-data-box">
              <div class="data-box-con">
                <div>
                  <div class="box-con-type"><i style=" background: #1cf9bc"></i>在线</div>
                  <div class="box-con-num"><count-up :end-val="boxList.online*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
                <div>
                  <div class="box-con-type"><i style=" background: #6674FF"></i>维修</div>
                  <div class="box-con-num"><count-up :end-val="boxList.repair*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
              </div>
              <div class="data-box-con">
                <div>
                  <div class="box-con-type"><i style=" background: #5793D7"></i>离线</div>
                  <div class="box-con-num"><count-up :end-val="boxList.offline*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
                <div>
                  <div class="box-con-type"><i style=" background: #FD486A"></i>报警</div>
                  <div class="box-con-num"><count-up :end-val="boxList.alarm*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dev-list">
          <div class="dev-title">
            <div class="dev-name">单灯总数</div>
            <div class="dev-num"><span class="textSiz"><count-up :end-val="lightList.count*1" :start-val="0" :key="countIndex"></count-up></span><i>个</i></div>
          </div>
          <div class="dev-data">
            <img :src="img.customized.ver3.devLamp" alt="">
            <div class="dev-data-box">
              <div class="data-box-con">
                <div>
                  <div class="box-con-type"><i style=" background: #1cf9bc"></i>在线</div>
                  <div class="box-con-num"><count-up :end-val="lightList.online*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
                <div>
                  <div class="box-con-type"><i style=" background: #6674FF"></i>维修</div>
                  <div class="box-con-num"><count-up :end-val="lightList.repair*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
              </div>
              <div class="data-box-con">
                <div>
                  <div class="box-con-type"><i style=" background: #5793D7"></i>离线</div>
                  <div class="box-con-num"><count-up :end-val="lightList.offline*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
                <div>
                  <div class="box-con-type"><i style=" background: #FD486A"></i>报警</div>
                  <div class="box-con-num"><count-up :end-val="lightList.alarm*1" :start-val="0" :key="countIndex"></count-up></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ranking-box">
        <div class="ranking-list">
          <rankingPage :option="{title:'配电箱安装数量排行',type:0}" :sorts='boxList.sorts'></rankingPage>
        </div>
        <div class="ranking-list">
          <rankingPage :option="{title:'单灯安装数量排行',type:1}" :sorts='lightList.sorts'></rankingPage>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from '../pubilc/title'
import CountUp from 'vue-countup-v2';
import rankingPage from './ranking'
export default {
  name: 'countPage2',
  components: {
    titlePage,
    CountUp,
    rankingPage
  },
  props: {
  },
  data() {
    return {
      title: {
        zh: '设备统计', en: 'EQUIPMENT STATISTICS'
      },
      boxList: {
        alarm: 0,
        count: 0,
        offline: 0,
        online: 0,
        repair: 0,
        sorts: []
      },
      lightList: {
        alarm: 0,
        count: 0,
        offline: 0,
        online: 0,
        repair: 0,
        sorts: []
      },
      countIndex: 0,
      stopTime: null,
    }
  },
  mounted: function () {
    this.setStat()
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 7000);
  },
  methods: {
    setStat() {

      this.$axios.post(`visual/home/QueryPowerbox`, {}).then(res => {
        if (res.code == 0) {
          let arr = res.data.sorts.slice(0, 5);
          let total = arr.reduce((sum, item) => sum + item.count, 0);
          res.data.sorts.forEach((el, index) => {
            if (index < 5) {
              el.rate = (el.count / total) * 100;
            }
          });
          this.$set(this, 'boxList', res.data);
        }
      })
      this.$axios.post(`visual/home/QueryLight`, {}).then(res => {
        if (res.code == 0) {
          let arr = res.data.sorts.slice(0, 5);
          let total = arr.reduce((sum, item) => sum + item.count, 0);
          res.data.sorts.forEach((el, index) => {
            if (index < 5) {
              el.rate = (el.count / total) * 100;
            }
          });
          this.$set(this, 'lightList', res.data);
        }
      })
    },
  },
  beforeDestroy() {
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.countPage2 {
  width: 100%;
  height: 100%;
}
.count-box {
  width: 100%;
  height: calc(100% - 10px);
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.dev-box {
  display: flex;
  width: calc(100%);
  justify-content: space-between;
}
.dev-list {
  width: calc(50%);
  justify-content: space-between;
}
.dev-title {
  display: flex;
  margin-top: 15px;
}
.dev-title .dev-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #bbdeff;
  display: inline-block;
  width: 40%;
  align-content: center;
}
.dev-title .dev-num span {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-shadow: 0px 0px 14px #002e66;
  background: linear-gradient(
    180deg,
    #e2f6ff 1.3671875%,
    #5a92d7 91.357421875%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.dev-title .dev-num i {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #9ab9d7;
  font-style: normal;
  margin-left: 5px;
}
.dev-list .dev-data {
  display: flex;
  justify-content: space-between;
}
.dev-list .dev-data img {
  width: 98px;
  height: 117px;
}
.dev-list .dev-data-box {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
}
.box-con-type {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #9ab9d7;
  margin-top: 20px;
}
.box-con-type i {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #1cf9bc;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
}
.box-con-num {
  font-family: Bahnschrift;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  text-shadow: 0px 0px 7px #0e398c;
  text-indent: 13px;
}
.ranking-box {
  width: calc(100%);
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  flex: 1 1 200px;
}
.ranking-list {
  width: calc(50% - 5px);
}
.textSiz {
  display: inline-block;
  animation: jump 2s 3;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
</style>
