<template>
  <div class="sortBox2Page">
    <titlePage :option='title'></titlePage>
    <div class="sort-box">
      <div class="sort-list">
        <div class="list-block list-block1 sort-color1">1</div>
        <div class="list-name sort-color1" :title="statData[0].name">{{statData[0].name}}</div>
        <div class="list-ratio">
          <div class="ratio-box"></div>
          <div class="ratio-cover ratio-cover1" :style=" {left: `${statData[0].rate}%`}">
            <div class="cover-box cover-box1"></div>
          </div>
          <div class="ratio-drop" :style="{width:`${statData[0].rate}%`,background: '#3FEED3'}"></div>
        </div>
        <div class="list-num"><span class="sort-color1">{{conversion(statData[0].conservation)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list">
        <div class="list-block list-block2 sort-color2">2</div>
        <div class="list-name sort-color2" :title="statData[1].name">{{statData[1].name}}</div>
        <div class="list-ratio">
          <div class="ratio-box"></div>
          <div class="ratio-cover ratio-cover2" :style=" {left: `${statData[1].rate}%`}">
            <div class="cover-box cover-box2"></div>
          </div>
          <div class="ratio-drop" :style="{width:`${statData[1].rate}%`,background: '#24C9FF'}"></div>
        </div>
        <div class="list-num"><span class="sort-color2">{{conversion(statData[1].conservation)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list">
        <div class="list-block list-block3 sort-color3">3</div>
        <div class="list-name sort-color3" :title="statData[2].name">{{statData[2].name}}</div>
        <div class="list-ratio">
          <div class="ratio-box"></div>
          <div class="ratio-cover ratio-cover3" :style=" {left: `${statData[2].rate}%`}">
            <div class="cover-box cover-box3"></div>
          </div>
          <div class="ratio-drop" :style="{width:`${statData[2].rate}%`,background: '#3A97FF'}"></div>
        </div>
        <div class="list-num"><span class="sort-color3">{{conversion(statData[2].conservation)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list">
        <div class="list-block ">4</div>
        <div class="list-name " :title="statData[3].name">{{statData[3].name}}</div>
        <div class="list-ratio">
          <div class="ratio-box"></div>
          <div class="ratio-cover" :style=" {left:`${statData[3].rate}%`}">
            <div class="cover-box cover-box4"></div>
          </div>
          <div class="ratio-drop" :style="{width:`${statData[3].rate}%`}"></div>
        </div>
        <div class="list-num"><span>{{conversion(statData[3].conservation)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list">
        <div class="list-block ">5</div>
        <div class="list-name" :title="statData[4].name">{{statData[4].name}}</div>
        <div class="list-ratio">
          <div class="ratio-box"></div>
          <div class="ratio-cover" :style=" {left:`${statData[4].rate}%`}">
            <div class="cover-box cover-box5"></div>
          </div>
          <div class="ratio-drop" :style="{width:`${statData[4].rate}%`}"></div>
        </div>
        <div class="list-num"><span>{{conversion(statData[4].conservation)}}</span><i>KWh</i></div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from '../pubilc/title.vue'
export default {
  name: 'sortBox2Page',
  components: {
    titlePage
  },
  props: {

  },
  data() {
    return {
      title: { zh: '节能排序', en: 'ENERGY SAVING SORTING' },
      statData: [
        {
          area: "",
          before: 0,
          conservation: 0,
          energy: 0,
          id: 0,
          name: "",
          rate: 0
        },
        {
          area: "",
          before: 0,
          conservation: 0,
          energy: 0,
          id: 0,
          name: "",
          rate: 0
        },
        {
          area: "",
          before: 0,
          conservation: 0,
          energy: 0,
          id: 0,
          name: "",
          rate: 0
        },
        {
          area: "",
          before: 0,
          conservation: 0,
          energy: 0,
          id: 0,
          name: "",
          rate: 0
        },
        {
          area: "",
          before: 0,
          conservation: 0,
          energy: 0,
          id: 0,
          name: "",
          rate: 0
        },
        {
          area: "",
          before: 0,
          conservation: 0,
          energy: 0,
          id: 0,
          name: "",
          rate: 0
        },
      ],
    }
  },
  watch: {

  },
  mounted: function () {
    this.QueryConservationSort()
  },
  methods: {
    QueryConservationSort() {
      this.$axios.post(`visual/home/QueryConservationSort`, {}).then(res => {
        if (res.code == 0) {
          let total = res.data.reduce((sum, item) => sum + item.conservation, 0);
          res.data.forEach(el => {
            el.rate = (el.conservation / total) * 100;
          });
          this.$set(this, 'statData', res.data);
        }
      })
    },
    conversion(num) {
      if (num < 100000) {
        return num;
      }
      let digit = ''
      digit = (num / 10000).toFixed(2) + '万'
      return digit;
    }
  }
}
</script>
<style scoped>
.sortBox2Page {
  width: 100%;
  height: 100%;
}
.sort-box {
  width: 100%;
  height: calc(100% - 40px);
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.sort-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.list-block {
  width: 26px;
  height: 26px;
  background: linear-gradient(
    90deg,
    rgba(73, 103, 163, 0.3),
    rgba(42, 63, 103, 0.3)
  );
  font-family: Arial;
  font-weight: 400;
  font-size: 18px;
  color: #9ab9d7;
  text-align: center;
  line-height: 26px;
  margin-right: 5px;
}
.list-block1 {
  background: linear-gradient(
    90deg,
    rgba(36, 149, 135, 0.3),
    rgba(42, 63, 103, 0.3)
  );
}
.list-block2 {
  background: linear-gradient(
    90deg,
    rgba(29, 174, 221, 0.3),
    rgba(42, 63, 103, 0.3)
  );
}
.list-block3 {
  background: linear-gradient(
    90deg,
    rgba(41, 139, 248, 0.3),
    rgba(42, 63, 103, 0.3)
  );
}
.list-name {
  width: 175px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-size: 14px;
  color: #c3e0fc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-num {
  color: #9ab9d7;
  width: 140px;
  text-align: right;
}
.list-num span {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 16px;
}
.list-num i {
  color: #6583a0;
}

.sort-color {
  color: #c3e0fc;
}
.sort-color1 {
  color: #3feed3;
}
.sort-color2 {
  color: #24c9ff;
}
.sort-color3 {
  color: #3a97ff;
}

.list-ratio {
  width: 351px;
  height: 4px;
  background: rgba(100, 154, 229, 0.5);
  border-radius: 2px;
  position: relative;
}

.ratio-drop {
  position: absolute;
  width: 50%;
  height: 4px;
  background: #9ab9d7;
  border-radius: 2px;
}
.ratio-cover {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  left: 50%;
  top: -5px;
  margin-left: -7px;
  background: radial-gradient(
    11px,
    rgba(154, 185, 215, 0.7),
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid rgba(154, 185, 215, 0.7);
  box-sizing: border-box;
}
.ratio-cover1 {
  background: radial-gradient(
    11px,
    rgba(81, 250, 224, 0.7),
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid rgba(81, 250, 224, 0.7);
}
.ratio-cover2 {
  background: radial-gradient(
    11px,
    rgba(58, 216, 255, 0.7),
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid rgba(58, 216, 255, 0.7);
}
.ratio-cover3 {
  background: radial-gradient(
    11px,
    rgba(58, 151, 255, 0.7),
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid rgba(58, 151, 255, 0.7);
}

.cover-box {
  width: 6px;
  height: 6px;
  background: #cde6ff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  left: 50%;
  margin-left: -3px;
  z-index: 50;
}
.cover-box1 {
  background: #a7fff1;
}
.cover-box2 {
  background: #91e9ff;
}
.cover-box3 {
  background: #3a97ff;
}
</style>
