<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>
<script>
// import Vue from 'vue';
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      // eventHub: new Vue()
      beforeUnload: 0,
      handlerTime: 0,
    }
  },
  computed: {
  },
  watch: {
  },

  // // 监听窗口大小变化事件
  // window.addEventListener('resize', adjustFontSize);

  mounted: function () {
    // console.log('app mounted')
    // window.addEventListener('unload', this.handleUnload);
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
    // let start = 0, diff = 0;
    // window.onbeforeunload = function(){
    //   start = new Date().getTime();
    // }
    // window.onunlod = function(){
    //   diff = new Date().getTime() - start;
    //   console.log('on unload', diff);
    // }
    // this.adjustFontSize();
    // window.addEventListener('resize', this.adjustFontSize);
  },
  beforeDestroy() {
    // 一定要在组件销毁前移除事件监听，避免内存泄露
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    // window.removeEventListener('unload', this.handleUnload);
  },
  methods: {
    adjustFontSize() {
      // const baseSize = 16;
      // const scale = window.devicePixelRatio || 1;
      // const newSize = baseSize * scale; 
      // const newSize = (baseSize / scale / baseSize * 100).toFixed(2); 
      console.log('newSize-----------')
      // console.log(newSize)
      const remSize = (document.documentElement.clientWidth / 30).toFixed(4)//1920 ---- 19.2 
      document.documentElement.style.fontSize = remSize + '%';
      console.log(remSize)
      //100 95%
      //150 63%
      //50  189%
    },
    handleBeforeUnload() {
      this.beforeUnload = new Date().getTime();
      // 在这里编写你想在页面关闭前执行的代码
      // 例如：发送数据或者确认提示
      // 设置returnValue以显示浏览器默认的离开确认对话框
      // event.returnValue = '你确定要离开吗？';
      // this.$store.dispatch('auth/logout')
    },
    handleUnload() {
      // 在这里编写你想在页面关闭前执行的代码
      // 例如：发送数据或者确认提示
      // 设置returnValue以显示浏览器默认的离开确认对话框
      // event.returnValue = '你确定要离开吗？';
      // this.$store.dispatch('auth/logout')
      this.handlerTime = new Date().getTime() - this.beforeUnload;
      if (this.handlerTime > 5) {
        console.log('退出？', this.handlerTime);
      } else {
        this.$store.dispatch('auth/logout')
        console.log('刷新？');
      }
    },
  },
}
</script>
<style>
@import "./assets/font/font.css";
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header {
  position: absolute;
  top: 0;
  height: 50px;
  left: 0;
  right: 0;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  right: 0;
}
.content {
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  right: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 100%;
  /* width: 4320px; */
  height: 100%;
  display: flex;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.alarm-high {
  color: red !important;
}
.alarm-low {
  color: red !important;
}
.status-blue {
  color: blue !important;
}
.status-green {
  color: #0ac937 !important;
}
</style>
