<template>
  <div class="sortBox1Page">
    <titlePage :option='title'></titlePage>
    <div class="sort-box">
      <div class="sort-list sort-list1">
        <div class="list-block list-block1">1</div>
        <div class="list-name list-name1">{{statData[0].name}}</div>
        <div class="list-city list-name1">{{statData[0].area}}</div>
        <div class="list-num list-name1"><span>{{conversion(statData[0].energy)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list sort-list2">
        <div class="list-block list-block2">2</div>
        <div class="list-name list-name2">{{statData[1].name}}</div>
        <div class="list-city list-name2">{{statData[1].area}}</div>
        <div class="list-num list-name2"><span>{{conversion(statData[1].energy)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list sort-list3">
        <div class="list-block list-block3">3</div>
        <div class="list-name list-name3">{{statData[2].name}}</div>
        <div class="list-city list-name3">{{statData[2].area}}</div>
        <div class="list-num list-name3"><span>{{conversion(statData[2].energy)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list sort-list4">
        <div class="list-block list-block4">4</div>
        <div class="list-name list-name4">{{statData[3].name}}</div>
        <div class="list-city list-name4">{{statData[3].area}}</div>
        <div class="list-num list-name4"><span>{{conversion(statData[3].energy)}}</span><i>KWh</i></div>
      </div>
      <div class="sort-list sort-list5">
        <div class="list-block list-block5">5</div>
        <div class="list-name list-name5">{{statData[4].name}}</div>
        <div class="list-city list-name5">{{statData[4].area}}</div>
        <div class="list-num list-name5"><span>{{conversion(statData[4].energy)}}</span><i>KWh</i></div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from '../pubilc/title.vue'
export default {
  name: 'sortBox1Page',
  components: {
    titlePage
  },
  props: {

  },
  data() {
    return {
      title: { zh: '能耗排序', en: 'ENERGY CONSUMPTION RANKING' },
      statData: [
        {
          area: "",
          energy: 0,
          id: 0,
          name: "0",
        },
        {
          area: "",
          energy: 0,
          id: 0,
          name: "0",
        },
        {
          area: "",
          energy: 0,
          id: 0,
          name: "0",
        },
        {
          area: "",
          energy: 0,
          id: 0,
          name: "0",
        },
        {
          area: "",
          energy: 0,
          id: 0,
          name: "0",
        },
      ]
    }
  },
  watch: {

  },
  mounted: function () {
    this.QueryEnergySort()
  },
  methods: {
    QueryEnergySort() {
      this.$axios.post(`visual/home/QueryEnergySort`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'statData', res.data);
        }
      })
    },
    conversion(num) {
      if (num < 100000) {
        return num;
      }
      let digit = ''
      digit = (num / 10000).toFixed(2) + '万'
      return digit;
    }
  }
}
</script>
<style scoped>
.sortBox1Page {
  width: 100%;
  height: 100%;
}
.sort-box {
  width: 100%;
  height: calc(100% - 42px);
  padding-left: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.sort-list {
  display: flex;
  width: 100%;
  height: calc(20% - 20px);
  padding: 10px 0;
  background: linear-gradient(
    90deg,
    rgba(18, 48, 107, 0.2),
    rgba(99, 144, 205, 0.2),
    rgba(99, 144, 205, 0.1),
    rgba(18, 48, 105, 0.2),
    rgba(18, 48, 105, 0.2)
  );
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.sort-list1 {
  background: linear-gradient(
    90deg,
    rgba(18, 48, 107, 0.2),
    rgba(216, 74, 74, 0.2),
    rgba(216, 74, 74, 0.1),
    rgba(18, 48, 105, 0.2),
    rgba(18, 48, 105, 0.2)
  );
}
.sort-list2 {
  background: linear-gradient(
    90deg,
    rgba(18, 48, 107, 0.2),
    rgba(252, 158, 23, 0.2),
    rgba(252, 158, 23, 0.1),
    rgba(18, 48, 105, 0.2),
    rgba(18, 48, 105, 0.2)
  );
}
.sort-list3 {
  background: linear-gradient(
    90deg,
    rgba(18, 48, 107, 0.2),
    rgba(185, 176, 33, 0.2),
    rgba(185, 176, 33, 0.1),
    rgba(18, 48, 105, 0.2),
    rgba(18, 48, 105, 0.2)
  );
}
.list-block {
  width: 26px;
  height: 26px;
  background: linear-gradient(
    90deg,rgba(88, 131, 217, 0.5),rgba(40, 67, 120, 0.5)
  );
  font-family: Arial;
  font-weight: 400;
  font-size: 14px;
  color: #9ab9d7;
  text-align: center;
  line-height: 26px;
  margin-left: 3px;
}

.list-block1 {
  background: linear-gradient(
    90deg,rgba(255, 105, 105, 0.5),rgba(39, 75, 146, 0.5)
  );
  color: #f45c5c;
}
.list-block2 {
  background: linear-gradient(
    90deg,rgba(255, 186, 87, 0.5),rgba(42, 63, 103, 0.5)
  );
  color: #fdb246;
}
.list-block3 {
  background: linear-gradient(
    90deg,rgba(236, 241, 116, 0.5),rgba(42, 63, 103, 0.5)
  );
  color: #e6ea6e;
}
.list-name {
  width: 150px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-size: 14px;
  color: #c3e0fc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-num {
  color: #9ab9d7;
  margin-right: 25px;
  width: 150px;
  text-align: right;
}
.list-num span {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 18px;
}
.list-num i {
  color: #6583A0;
}
.list-city {
  width: 150px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-size: 16px;
  color: #9ab9d7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-name1 {
  color: #E45959;
}
.list-name2 {
  color: #DCA24F;
}
.list-name3 {
  color: #D0D46E;
}
.list-name4 {
  color: #9AB9D7;
}
.list-name5 {
  color: #C3E0FC;
}
.sort-list::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(77, 118, 173, 1),
    rgba(77, 118, 173, 0.1),
    rgba(255, 0, 0, 0)
  );
}

.sort-list1::after {
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(149, 36, 36, 1),
    rgba(149, 36, 36, 0.1),
    rgba(255, 0, 0, 0)
  );
}
.sort-list2::after {
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(162, 101, 14, 1),
    rgba(162, 101, 14, 0.1),
    rgba(255, 0, 0, 0)
  );
}
.sort-list3::after {
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(139, 131, 11, 1),
    rgba(139, 131, 11, 0.1),
    rgba(255, 0, 0, 0)
  );
}
/* .sort-list::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; 
  height: 100%; 
  background: linear-gradient(to top, rgba(255, 0, 0, 0), rgba(77, 118, 173, 1),rgba(77, 118, 173, 0.1),rgba(255, 0, 0, 0)); 
} */
</style>
